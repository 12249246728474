/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
	description?: string
	lang?: string
	meta?: []
	title: string
	type?: "website" | "article";
	image?: string;
}

const SEO = ({ description, lang, meta, title, type, image }: Props) => {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
			description
			siteUrl
            social { twitter }
          }
        }
      }
    `
	) as {
		site: {
			siteMetadata: {
				title: string;
				description: string;
				siteUrl: string;
				social: {
					twitter: string;
				}
			}
		}
	}

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: type ?? "website",
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.social.twitter,
				},
				{
					name: `twitter:site`,
					content: `@ifelseconsult`
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					property: "og:image",
					content: image === undefined ? `${site.siteMetadata.siteUrl}/logo.png` : `${site.siteMetadata.siteUrl}${image}`
				}
			].concat(meta || [])}
		/>
	)
}

export default SEO