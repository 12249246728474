import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';

interface MarkdownComponentProps {
	slug: string;
}

interface Query {
	allMdx: {
		edges: {
			node: {
				body: string;
				fields: {
					slug: string;
				}
				frontmatter?: {
					style?: string | null;
				}
			}
		}[]
	}
}

function MarkdownComponent({ slug }: MarkdownComponentProps) {
	const components: Query = useStaticQuery(graphql`
    {
		allMdx(filter: {collection: {eq: "components"}}) {
		  edges {
			node {
			  body
			  fields {
				slug
			  }
			  frontmatter{
				  style
			  }
			}
		  }
		}
	}	  
  `);

	const component = components.allMdx.edges.find(edge => edge.node.fields.slug === `/${slug}/`)
	if (component) {
		return <>
			<style dangerouslySetInnerHTML={{ __html: component.node.frontmatter?.style ?? "" }} />
			<MDXRenderer>{component.node.body}</MDXRenderer>
		</>;
	}
	return <></>;
}

export default MarkdownComponent