/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import MarkdownComponent from "./MarkdownComponent"

const Bio = () => {
	const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/bs.png/" }) {
        childImageSharp {
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

	const { author, social } = data.site.siteMetadata
	return (
		<div
			style={{
				display: `flex`,
				marginBottom: rhythm(2.5),
			}}
		>
			<div>
				<div>
					<MarkdownComponent slug="about" />
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}>
						<Image
							fixed={data.avatar.childImageSharp.fixed}
							alt={author.name}
							style={{
								marginRight: rhythm(1 / 2),
								marginBottom: 0,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Bio
