import React from 'react';
import { MDXProvider } from '@mdx-js/react';

const ShortCodes: React.SFC<{}> = ({ children }) => {
	const shortCodes = {};
	return <MDXProvider components={shortCodes}>
		{children}
	</MDXProvider>
}

export default ShortCodes