import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from "gatsby-image";

interface LogoProps {
	style?: React.CSSProperties;
}

function Logo({ style }: LogoProps) {
	const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(absolutePath: { regex: "/if-else-consulting-colors.png/" }) {
        childImageSharp {
          fixed(width: 95, height: 28) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
	return <Image fixed={data.logo.childImageSharp.fixed}
		alt="logo"
		style={{ ...{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }, ...style }}
	/>
}

export default Logo