import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
	return {
		"a.gatsby-resp-image-link": {
			boxShadow: `none`,
		},
		"ul, ol ": {
			marginLeft: "1rem"
		},
		"blockquote": {
			marginLeft: "0rem !important"
		},
		"h3": {
			lineHeight: "1.4"
		}
	}
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
	typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
