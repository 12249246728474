import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import ShortCodes from "./ShortCodes";
import './layout.css';
import Logo from "./logo";

interface Props {
	children?: any
}
declare var __PATH_PREFIX__: string;
const rootPath = `${__PATH_PREFIX__}/`

const Layout = ({ children }: Props) => {

	return (
		<ShortCodes>
			<div style={{ backgroundColor: '#070750', color: 'white', padding: 10, position: 'sticky', top: 0, zIndex: 1 }}>
				<nav style={{ marginLeft: `auto`, marginRight: `auto`, maxWidth: rhythm(24), display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
					<Link style={{ boxShadow: 'none' }} to={'/'}><Logo /></Link>
					<Link style={{ boxShadow: `none`, textDecoration: `none`, color: `inherit`, }} to={`/`} >Home</Link>
					<Link style={{ boxShadow: `none`, textDecoration: `none`, color: `inherit`, }} to={`/articles`} >Articles</Link>
					<Link style={{ boxShadow: `none`, textDecoration: `none`, color: `inherit`, }} to={`/services`} >Services</Link>
				</nav>
			</div>
			<div style={{ marginLeft: `auto`, marginRight: `auto`, maxWidth: rhythm(24), padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`, }} >
				<main>{children}</main>
				<footer style={{ display: 'flex' }}>
					<Logo style={{ filter: "grayscale(100%)" }} />
					<span style={{ marginLeft: 20, color: 'gray' }}>© {new Date().getFullYear()}</span>
				</footer>
			</div>
		</ShortCodes>
	)
}

export default Layout